<template>
 
  <router-view/>
</template>

<style lang="less">
#app {
  margin:0;
  padding:0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
