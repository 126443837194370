import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import locale from 'element-plus/lib/locale/lang/zh-cn' 
import ElementPlus from 'element-plus'
import {ElDatePicker,ElForm} from 'element-plus'
import 'element-plus/dist/index.css'


createApp(App).use(store).use(router)
.use(ElementPlus,{locale})
.use(ElDatePicker)
.use(ElForm)

.mount('#app')
